<template>
  <div class="wrapper">
    <base-button
      icon
      type="primary"
      class="flotingBtn"
      :class="[isActive ? 'tagBtn2' : 'tagBtn']"
      @click="isActive = !isActive"
    >
      <span class="btn-inner--icon"><i class="ni ni-bold-right"></i></span>
    </base-button>
    <base-button
      icon
      type="primary"
      class="flotingBtn2"
      @click="toggleClass"
      :class="[isActive ? 'tagBtn' : 'tagBtn2']"
    >
      <span class="btn-inner--icon"><i class="ni ni-bold-left"></i></span>
    </base-button>
    <Sidebar :class="{ tagBtn: isActive }" />
    <div class="main-content" :class="{ DashboardBody: isActive }">
      <SampleNavbar />
      <base-header class="pb-6 pb-8 pt-5 pt-md-8 bg-gradient-success">
        <b-breadcrumb>
          <b-breadcrumb-item href="#">Registration</b-breadcrumb-item>
          <b-breadcrumb-item href="#" active
            >Registration List</b-breadcrumb-item
          >
        </b-breadcrumb>
      </base-header>
      <b-container fluid class="mt--7">
        <b-row class="mb-2">
          <b-col md="10"></b-col>
          <b-col md="2">
            <!-- <base-button block type="primary" @click="csvExport(csvData)"
              >Export to CSV</base-button
            > -->
            <download-csv
              class="btn btn-default"
              :data="rows"
              name="registration.csv"
            >
              Export to CSV
            </download-csv>
          </b-col>
        </b-row>
        <b-card type="default" no-body>
          <vue-good-table
            :columns="columns"
            :rows="rows"
            :line-numbers="true"
            :search-options="{
              enabled: true,
              placeholder: 'Search this table',
            }"
            :pagination-options="{
              enabled: true,
              mode: 'records',
            }"
          >
            <template slot="table-row" slot-scope="props" style="w">
              <span v-if="props.column.field == 'after'">
                <base-button
                  icon
                  type="danger"
                  size="sm"
                  @click="regDelete(props.row.contact_id)"
                >
                  <span class="btn-inner--icon">
                    <!-- <i class="far fa-trash-o"></i> -->
                    <b-icon icon="trash-fill" aria-hidden="true"></b-icon>
                  </span>
                </base-button>
              </span>
              <span v-else>
                {{ props.formattedRow[props.column.field] }}
              </span>
            </template>
          </vue-good-table>
        </b-card>
      </b-container>
      <Footer />
    </div>
  </div>
</template>
<script>
import Sidebar from "./../../Starter/Sidebar";
import SampleNavbar from "./../../Starter/SampleNavbar";
import Footer from "./../../Starter/SampleFooter";
import { API_URL, SITE_URL } from "../../../router/api";
import JsonCSV from "vue-json-csv";
export default {
  name: "service-list",
  components: {
    Sidebar,
    SampleNavbar,
    Footer,
    downloadCsv: JsonCSV
  },
  //   setup() {
  //     const column = ref([
  //       {
  //         label: "Contact Name",
  //         field: "contact_name"
  //       },
  //       {
  //         label: "Contact Phone",
  //         field: "contact_phone"
  //       },
  //       {
  //         label: "Contact City",
  //         field: "contact_city"
  //       },
  //       {
  //         label: "Actions",
  //         field: "after"
  //       }
  //     ]);
  //     return {
  //         column
  //     }
  //   },
  data() {
    return {
      isActive: false,
      columns: [
        {
          label: "Contact Name",
          field: "contact_name"
        },
        {
          label: "Contact Phone",
          field: "contact_phone"
        },
        {
          label: "Contact City",
          field: "contact_city"
        },
        {
          label: "Date/Time",
          field: "contact_gen_date"
        },
        {
          label: "Actions",
          field: "after"
        }
      ],
      longData: null
    };
  },
  computed: {
    rows() {
      return this.$store.state.registrationList === null
        ? []
        : this.$store.state.registrationList;
    }
  },
  methods: {
    toggleClass: function(event) {
      this.isActive = !this.isActive;
    },
    regDelete(cId) {
      const self = this;
      self.axios
        .post(`${API_URL}admin_registration_delete`, {
          id: cId
        })
        .then(response => {
            console.log(cId);
          if (response.data.status == true) {
            self.$store.dispatch("getRegistrationList");
            self.$store.dispatch("alertSuccess", response.data.message);
          } else {
            self.$store.dispatch("alertError", response.data.message);
          }
        });
    }
  },
  beforeCreate() {
    this.$store.dispatch("getRegistrationList");
  },
  mounted() {}
};
</script>
<style lang="scss">
.DashboardBody {
  margin-left: 0px !important;
}
.tagBtn {
  display: none !important;
}
.tagBtn2 {
  display: block !important;
}
</style>

